import { ImageResult } from '@/types/APITypes'
import { preloadImages } from '@/lib/utils'

export async function fetchImages(
  currentIndex: number = 0,
  numberOfImages: number = 1
): Promise<ImageResult[]> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/images?numberofImages=${numberOfImages}&currentIndex=${currentIndex}` as string,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    if (!response.ok) {
      const error = await response.json()
      alert('Error fetching images: ' + error.message)
    }
    const data = await response.json()
    await preloadImages(data.queue.map((image: ImageResult) => image.url))
    return data.queue
  } catch (error) {
    console.error(error)
    return []
  }
}
