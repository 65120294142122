import { Badge } from '@/components/Badge'
import { Button } from '@/components/Button'
import { ProgressSection } from '../../components/ProgressSection'
import { ImageResult } from '@/types/APITypes'
import { useState, useEffect } from 'react'
import { Coin } from '@/components/Coin'
import { PlayViewProps } from '@/types/PlayViewTypes'
import NotificationMessage from '@/components/NotificationMessage'
import { Confetti } from '@/components/Confetti'
import {
  applyRandomSwap,
  checkAnswer,
  getRandomPhrase,
  renderImageSection,
  // storeAnswer,
} from '@/views/PlayView/functions'
import { trimPinataUrl, updateAnswer, logger } from '@/lib/utils'
import { flagAnswer } from '@/lib/api/flagAnswer'
import BetaView from '@/views/BetaView'
import { Modal } from '@/components/Modal'
import { useTelegram } from '@/contexts/TelegramContext'

export const SWAP_ANSWER_THRESHOLD = parseFloat(
  process.env.NEXT_PUBLIC_SWAP_ANSWER_THRESHOLD ?? '0.25'
)

const BASE_REQUIREMENT = parseInt(
  process.env.NEXT_PUBLIC_BASE_REQUIREMENT ?? '10'
)

export default function PlayView({
  onYes,
  onNo,
  progress = 0,
  images = [],
  currentScore = 0,
  nextReward = 0,
  dailyStreak = 0,
  multiplier = '1.0',
  currentStreak = 0,
  refetchUserData,
  level = 1,
}: PlayViewProps) {
  const [imageQueue, setImageQueue] = useState<ImageResult[]>(images)
  const [isImageLoading, setIsImageLoading] = useState(true)
  const [playTime, setPlayTime] = useState<number>(0)
  const [hasStarted, setHasStarted] = useState(false)
  const [answerSwap, setAnswerSwap] = useState<string | null>(null)
  const [showAffirmation, setShowAffirmation] = useState(false)
  const [currentAffirmation, setCurrentAffirmation] = useState<string>('')
  const [showConfetti, setShowConfetti] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [currentWarning, setCurrentWarning] = useState('')
  const [_currentStreak, setCurrentStreak] = useState(0)
  const [_currentMultiplier, setCurrentMultiplier] = useState(multiplier)
  const [_progress, setProgress] = useState(0)
  const [percentage, setPercentage] = useState(0)
  const [totalRightAnswers, setTotalRightAnswers] = useState(0)
  const [currentThreshold, setCurrentThreshold] = useState(0)
  const [nextThreshold, setNextThreshold] = useState(0)
  const [currentLevel, setCurrentLevel] = useState(level)
  const [showBetaInfo, setShowBetaInfo] = useState(false)
  const [submittingAnswer, setSubmittingAnswer] = useState(false)
  const [isFirstAnswer, setIsFirstAnswer] = useState(false)
  const [showFlagModal, setShowFlagModal] = useState(false)
  const { user, hash } = useTelegram()

  useEffect(() => {
    if (images.length > 0) {
      // sometimes the API returns images with no url or subject
      const validImages = images.filter((img) => img.url && img.subject)
      setImageQueue(validImages)
    }
  }, [images])

  useEffect(() => {
    if (imageQueue.length > 0) {
      setCurrentAffirmation(getRandomPhrase())
      setIsImageLoading(true)
      setPlayTime(0)
    }
  }, [imageQueue])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>

    if (!isImageLoading && hasStarted) {
      interval = setInterval(() => {
        setPlayTime((prev) => prev + 1)
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isImageLoading, hasStarted])

  const handleImageLoad = () => {
    setIsImageLoading(false)
    setAnswerSwap(applyRandomSwap())
    if (!hasStarted) {
      setHasStarted(true)
    }
  }

  const handleCorrectAnswer = () => {
    setCurrentAffirmation(getRandomPhrase())
    setShowAffirmation(true)
  }

  const calculateThresholds = (
    level: number,
    baseRequirement: number,
    growthFactor: number
  ) => {
    let currentLevel = level
    let currentThresholdValue = currentThreshold
    let nextThresholdValue = nextThreshold

    while (totalRightAnswers >= nextThresholdValue) {
      currentLevel++
      currentThresholdValue = nextThresholdValue

      // Calculate next threshold with growth factor
      const increase = Math.ceil(
        baseRequirement * Math.pow(growthFactor, currentLevel - 1)
      )
      nextThresholdValue = currentThresholdValue + Math.min(increase, 30)
    }

    // Update state
    setCurrentThreshold(currentThresholdValue)
    setNextThreshold(nextThresholdValue)
    setCurrentLevel(currentLevel)

    return {
      currentThreshold: currentThresholdValue,
      nextThreshold: nextThresholdValue,
    }
  }

  const calculateProgress = () => {
    // Prevent division by zero
    if (currentThreshold === nextThreshold) {
      setProgress(1)
      setPercentage(1)
      return
    }

    const progress =
      ((totalRightAnswers - currentThreshold) /
        (nextThreshold - currentThreshold)) *
      100
    const percentage = Math.min(100, Math.max(0, progress))
    setProgress(progress)
    setPercentage(percentage)
  }

  const handleFlagSubmit = () => {
    flagAnswer({
      hash: `hash=${hash}`,
      telegramId: user?.id.toString() ?? '',
    }).then(() => {
      setShowFlagModal(false)
    })
  }

  const handleAnswer = (answer: 'yes' | 'no') => {
    if (imageQueue.length > 0) {
      setIsFirstAnswer(true)
      const currentImage = imageQueue[0]
      const cid = trimPinataUrl(currentImage.url)
      const isCorrect = checkAnswer(
        answer,
        answerSwap,
        currentImage.subject.toLowerCase(),
        currentImage.subject.toLowerCase(),
        setShowConfetti
      )

      if (isCorrect) {
        setTotalRightAnswers(totalRightAnswers + 1)
        handleCorrectAnswer()
        calculateThresholds(currentLevel, BASE_REQUIREMENT, 1.1)
        calculateProgress()
        setCurrentStreak(_currentStreak + 1)
        const _multiplier = parseFloat(_currentMultiplier)
        if (_multiplier >= 5) {
          setCurrentMultiplier((_multiplier + 1).toFixed(0).toString())
        } else {
          setCurrentMultiplier((_multiplier + 0.1).toFixed(1).toString())
        }
      } else {
        //
        setCurrentWarning(getRandomPhrase('warning'))
        if (_currentStreak > 0) {
          setCurrentWarning('Oh no, you broke your streak!')
          setCurrentStreak(0)
          setCurrentMultiplier('1.0')
        } else if (_currentStreak <= 0) {
          setCurrentStreak(_currentStreak - 1)
        }
        if (_currentStreak === -2) {
          setCurrentWarning(
            `3 wrong answers in a row. 5 wrong and it's a 50 point penalty!`
          )
        } else if (_currentStreak === -4) {
          setCurrentWarning(
            `5 wrong answers in a row. You lost 50 points! 10 wrong in a row and the game is paused for 24 hours. Don't let that happen!!`
          )
        } else if (_currentStreak === -9) {
          setCurrentWarning(
            `10 wrong answers in a row. Game paused for 24 hours. Bummer!`
          )
        }
        setShowWarning(true)
      }

      // storeAnswer({
      //   image: currentImage,
      //   answer,
      //   answerSwap,
      //   correctAnswer: currentImage.subject,
      //   playTime,
      // })
      updateAnswer({
        telegramId: user?.id.toString() ?? '',
        hash: `hash=${hash}`,
        id: currentImage.id,
        cid: cid,
        rightAnswer: currentImage.subject,
        realCategory: currentImage.subject,
        displayedCategory: answerSwap ?? currentImage.subject,
        timeSpent: playTime,
      })
        .then(() => {
          setSubmittingAnswer(true)
        })
        .finally(() => {
          setSubmittingAnswer(false)
        })

      setImageQueue((prev) => prev.slice(1))
      setPlayTime(0)
    }

    if (answer === 'yes') {
      onYes?.()
    } else {
      onNo?.()
    }
  }

  if (
    imageQueue.length === 0 &&
    !showAffirmation &&
    !showWarning &&
    !showConfetti
  ) {
    return (
      <div className="fixed inset-0 flex flex-col">
        <div className="bg-gray-900 p-4 border-b border-gray-800">
          <div className="flex justify-between items-center max-w-lg mx-auto w-full">
            <Badge
              variant="info"
              showIcon={true}
              icon="testTube"
              onInfoClick={() => {
                setShowBetaInfo(true)
              }}
            >
              BETA
            </Badge>
            <div className="flex items-center gap-1 text-sm text-gray-600">
              <Coin /> {currentScore.toLocaleString()}
            </div>
            <div className="flex items-center gap-1 text-xs text-gray-600">
              <span className="text-yellow-500">🗓️</span> {dailyStreak}
            </div>
            {showBetaInfo && (
              <BetaView onClose={() => setShowBetaInfo(false)} />
            )}
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="max-w-lg mx-auto p-4 text-center space-y-8">
            <Button
              variant="retro"
              size="lg"
              onClick={() => refetchUserData?.()}
              className="font-press-start w-full"
            >
              Play Again
            </Button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 flex flex-col">
      <div className="bg-gray-900 p-4 border-b border-gray-800">
        <div className="flex justify-between items-center max-w-lg mx-auto w-full">
          <Badge
            variant="info"
            showIcon={true}
            icon="testTube"
            onInfoClick={() => {
              setShowBetaInfo(true)
            }}
          >
            BETA
          </Badge>
          <div className="flex items-center gap-1 text-sm text-gray-600">
            <Coin /> {currentScore.toLocaleString()}
          </div>
          <div className="flex items-center gap-1 text-xs text-gray-600">
            <span className="text-yellow-500"> 🗓️</span> {_currentStreak}
          </div>
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <div className="max-w-lg mx-auto p-4 space-y-8">
          <div className="flex justify-center">
            <Modal
              hideCloseButton={true}
              title="Flag Answer?"
              onClose={() => setShowFlagModal(false)}
              footer={
                <div>
                  <Button variant="retro" onClick={handleFlagSubmit}>
                    Flag
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setShowFlagModal(false)}
                  >
                    Cancel
                  </Button>
                </div>
              }
              isOpen={showFlagModal}
            >
              <div className="text-md mb-4">Flag Answer</div>
              <div className="text-sm text-gray-600">
                Flagging answers is imporant for improving AI models and earn
                rewards. Watch out, false flags get penalties!
              </div>
            </Modal>
            {showAffirmation && (
              <NotificationMessage
                message={currentAffirmation}
                duration={3000}
                onDismiss={() => setShowAffirmation(false)}
                noTimer={true}
              />
            )}
            {showWarning && (
              <NotificationMessage
                message={currentWarning}
                duration={4000}
                variant="warning"
                onDismiss={() => setShowWarning(false)}
                noTimer={true}
              />
            )}
            {imageQueue[0] ? (
              renderImageSection(
                answerSwap,
                imageQueue[0],
                _currentMultiplier,
                isImageLoading,
                handleImageLoad
              )
            ) : (
              <div>Loading images...</div>
            )}
          </div>
          {imageQueue.length === 0 && (
            <div className="flex justify-center">
              <ProgressSection
                progress={_progress}
                nextReward={Math.floor(currentLevel * BASE_REQUIREMENT)}
                currentLevel={currentLevel}
                showConfetti={false}
              />
            </div>
          )}
          {showBetaInfo && <BetaView onClose={() => setShowBetaInfo(false)} />}
          {imageQueue.length > 0 && (
            <div className="grid grid-cols-2 gap-4">
              <Button
                variant="no"
                size="lg"
                onClick={() => handleAnswer('no')}
                className="font-press-start w-full"
                disabled={submittingAnswer || isImageLoading}
              >
                NO
              </Button>
              <Button
                variant="yes"
                size="lg"
                onClick={() => handleAnswer('yes')}
                className="font-press-start w-full"
                disabled={submittingAnswer || isImageLoading}
              >
                YES
              </Button>
            </div>
          )}
          {imageQueue.length > 0 && (
            <div className="max-w-lg mx-auto w-full px-4 flex justify-center">
              <Button
                variant="retro"
                size="sm"
                className="font-press-start w-full text-center"
                disabled={submittingAnswer || !isFirstAnswer || isImageLoading}
                onClick={() => setShowFlagModal(true)}
              >
                Flag Last Answer
              </Button>
            </div>
          )}
        </div>
      </div>

      {showConfetti && (
        <Confetti duration={1000} onComplete={() => setShowConfetti(false)} />
      )}
    </div>
  )
}
