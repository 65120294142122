import React, { createContext, useContext, useEffect, useState } from 'react'
import WebApp from '@twa-dev/sdk'

interface TelegramContext {
  webApp: typeof WebApp
  user: typeof WebApp.initDataUnsafe.user
  ready: boolean
  hash: string | undefined
  initData: string
}

const TelegramContext = createContext<TelegramContext>({
  webApp: WebApp,
  user: undefined,
  ready: false,
  hash: undefined,
  initData: '',
})

export function TelegramProvider({ children }: { children: React.ReactNode }) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    WebApp.ready()
    setReady(true)
  }, [])

  const value = {
    webApp: WebApp,
    user: WebApp.initDataUnsafe.user,
    ready,
    hash: WebApp.initDataUnsafe.hash,
    initData: WebApp.initData,
  }

  return (
    <TelegramContext.Provider value={value}>
      {children}
    </TelegramContext.Provider>
  )
}

export const useTelegram = () => useContext(TelegramContext)
