import { ImageResult, UpdateAnswerRequest, UserData } from '@/types/APITypes'
import { PlayTimeData, RankData, ResponseData } from '@/types/StorageTypes'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

const STORAGE_KEY = process.env.REACT_APP_TEST_ID
const lastImageSet: number[] = []
// tailwind merge util
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export interface FetchUserProps {
  telegramUserName: string | undefined
  referrerId: number
  telegramId: string
  numberOfImagesToFetch: number | string
  hash: string
}

export function calculateRightAnswer(
  rightAnswer: string,
  displayedCategory: string,
  realCategory: string
): number {
  if (displayedCategory === realCategory) {
    // When displayed matches real, user should have answered "yes"
    return rightAnswer === realCategory ? 1 : 0
  } else {
    // When displayed doesn't match real, user should have answered "no"
    return rightAnswer === realCategory ? 0 : 1
  }
}

// NADER ---> fix this logic plz...
export function deriveAnswerFromResult(
  result: number,
  displayedCategory: string,
  realCategory: string
): 'Yes' | 'No' {
  if (displayedCategory === realCategory) {
    return result === 1 ? 'Yes' : 'No'
  } else {
    return result === 1 ? 'No' : 'Yes'
  }
}
export async function updateAnswer({
  telegramId,
  hash,
  id,
  cid,
  rightAnswer,
  realCategory,
  displayedCategory,
  timeSpent,
}: UpdateAnswerRequest) {
  const payload = {
    telegramId,
    hash,
    id,
    cid,
    rightAnswer: calculateRightAnswer(
      rightAnswer,
      displayedCategory,
      realCategory
    ),
    realCategory,
    displayedCategory,
    timeSpent,
    datetime: new Date().toISOString(),
  }
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/handleAnswer` as string,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )
    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

// player leveling stuff

export const calculateLevel = (totalRightAnswers: number): number => {
  let level = 1
  let currentThreshold = 0
  const baseRequirement = 10
  let nextThreshold = baseRequirement
  const growthFactor = 1.05
  while (totalRightAnswers >= nextThreshold) {
    level++
    currentThreshold = nextThreshold
    nextThreshold += Math.ceil(
      baseRequirement * Math.pow(growthFactor, level - 1)
    )
    if (nextThreshold - currentThreshold > 30) {
      nextThreshold = currentThreshold + 30
    }
  }
  return level
}

// storage stuff

export const storeUserData = (data: UserData) => {
  localStorage.setItem(`${STORAGE_KEY}-userData`, JSON.stringify(data))
}

export const storeRankData = (data: RankData) => {
  localStorage.setItem(`${STORAGE_KEY}-rankData`, JSON.stringify(data))
}

export const storePlayTimeData = (data: PlayTimeData) => {
  localStorage.setItem(`${STORAGE_KEY}-playTimeData`, JSON.stringify(data))
}

export const storeResponseData = ({
  imageData,
  answer,
  answerSwap,
  correctAnswer,
  playTime,
}: {
  imageData: ImageResult
  answer: string
  answerSwap: string | null
  correctAnswer: string | null
  playTime: number
}) => {
  const existingData = getResponseData()
  if (existingData) {
    existingData.responses.push({
      imageData,
      answer,
      answerSwap,
      correctAnswer,
      playTime,
    })
    localStorage.setItem(
      `${STORAGE_KEY}-responseData`,
      JSON.stringify(existingData)
    )
  } else {
    localStorage.setItem(
      `${STORAGE_KEY}-responseData`,
      JSON.stringify({
        responses: [{ imageData, answer, answerSwap, correctAnswer }],
      })
    )
  }
}

export const getUserData = (): UserData | null => {
  const data = localStorage.getItem(`${STORAGE_KEY}-userData`)
  return data ? JSON.parse(data) : null
}

export const getRankData = (): RankData | null => {
  const data = localStorage.getItem(`${STORAGE_KEY}-rankData`)
  return data ? JSON.parse(data) : null
}

export const getPlayTimeData = (): PlayTimeData | null => {
  const data = localStorage.getItem(`${STORAGE_KEY}-playTimeData`)
  return data ? JSON.parse(data) : null
}

export const getResponseData = (): ResponseData | null => {
  const data = localStorage.getItem(`${STORAGE_KEY}-responseData`)
  return data ? JSON.parse(data) : null
}

export function trimPinataUrl(url: string): string {
  const start = url.indexOf('mypinata.cloud/ipfs/')
  const end = url.indexOf('?pinataGatewayToken=')

  // "https://teal-effective-roundworm-68.mypinata.cloud/ipfs/bafkreiclv4psh7slemeeuraeqtgtab6gs6ziqxbl2v6iowpc6cw4drzc4e?pinataGatewayToken=jHxdLPRGmwAELnvYhhpMeqPqguHCdH3Qrpuk1YigmvHpjlvLbtILr3A1mg6QJz9"

  if (start === -1) {
    throw new Error("URL does not contain 'mypinata.cloud/ipfs/'")
  }

  const trimmedUrl =
    end !== -1
      ? url.substring(start + 'mypinata.cloud/ipfs/'.length, end)
      : url.substring(start + 'mypinata.cloud/ipfs/'.length)

  return trimmedUrl
}

export const logger = (...args: any[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

export const preloadImages = (urls: string[]): Promise<void[]> => {
  const loadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = url
      img.onload = () => resolve()
      img.onerror = () => reject()
    })
  }

  return Promise.all(urls.map((url) => loadImage(url)))
}
