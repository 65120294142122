import { UserDataResponse, ImageResult } from '@/types/APITypes'
import { fetchImages } from '@/lib/api/fetchImages'
import { fetchRank } from '@/lib/api/fetchRank'
import { FetchUserProps, preloadImages } from '@/lib/utils'

export async function fetchUser({
  telegramUserName,
  referrerId = -1,
  telegramId,
  numberOfImagesToFetch = parseInt(
    process.env.REACT_APP_NUMBER_OF_IMAGES_TO_FETCH ?? '10'
  ),
  hash,
}: FetchUserProps): Promise<UserDataResponse | null> {
  if (!telegramUserName) {
    return null
  }
  try {
    const decodedHash = decodeURIComponent(hash)
    const response = await fetch(`${process.env.REACT_APP_API_ROOT}/telethon`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        telegramUserName,
        referrerId,
        telegramId,
        numberOfImagesToFetch,
        hash: decodedHash,
      }),
    })
    if (!response.ok) {
      const error = await response.json()
      alert('Error fetching user: ' + JSON.stringify(error))
    }
    const data = await response.json()
    const images = await fetchImages(
      data.userData.currentimageindex,
      parseInt(process.env.REACT_APP_NUMBER_OF_IMAGES_TO_FETCH ?? '10')
    )
    // const rankData = await fetchRank({
    //   telegramId,
    //   totalRightAnswers: data.userData.totalrightanswers,
    //   currentStreak: data.userData.currentstreak,
    //   multiplier: data.userData.multiplier,
    //   score: data.userData.score,
    //   totalAnswers: data.userData.totalanswers,
    //   dailyStreak: data.userData.dailystreak,
    //   dateLastPlayed: data.userData.datelastplayed,
    //   hash: decodedHash,
    // })

    await preloadImages(images.map((image: ImageResult) => image.url))

    return {
      ...data,
      queue: images,
      userData: {
        ...data.userData,
        totalAnswers: data.userData.totalanswers,
        totalRightAnswers: data.userData.totalrightanswers,
        currentstreak: data.userData.currentstreak,
        dailyStreak: data.userData.dailystreak,
        multiplier: data.userData.multiplier,
        //rank: rankData,
        rank: [],
        telegramId,
      },
    }
  } catch (error) {
    console.error(error)
    alert('Error fetching user, final catch: ' + error)
    return null
  }
}
