import { useTelegram } from '@/contexts/TelegramContext'
import { useEffect } from 'react'
import { useState } from 'react'

export function SplashScreenView() {
  const { user, hash, initData } = useTelegram()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(false)
  }, [user])

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="text-center">
        <div className="text-xs font-bold">Crypto AI</div>
        {isLoading && <div className="text-2xs">Loading...</div>}
        <div>
          <div className="text-2xs">{user?.username}</div>
          <div className="text-2xs">{user?.id}</div>
          <div className="text-2xs">Hash: {hash}</div>
          <div className="text-2xs break-all">InitData: {initData}</div>
        </div>
      </div>
    </div>
  )
}
